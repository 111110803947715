import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Definice a dějiny geodézie" />
    <h1>Definice a dějiny geodézie</h1>
    <p>V&Uacute;GTK definuje geod&eacute;zii jako př&iacute;rodn&iacute; vědu, jednu z věd o Zemi, kter&aacute; pomoc&iacute; geometrick&yacute;ch a fyzik&aacute;ln&iacute;ch metod z&iacute;sk&aacute;v&aacute; o Zemi &uacute;daje metrick&eacute;ho a fyzik&aacute;ln&iacute;ho charakteru. Jde současně tak&eacute; o technick&yacute; obor, zji&scaron;ťuj&iacute;c&iacute; geometrick&eacute; &uacute;daje pro tvorbu map a pro potřeby jin&yacute;ch oborů.</p>
    <p>Pojem geod&eacute;zie m&aacute; z&aacute;klad v&nbsp;řečtině ve slovech geo (země) a daisia (dělen&iacute;). Doslovně by tedy mělo j&iacute;t o &bdquo;dělen&iacute; země&ldquo; &ndash; to skutečně byla činnost star&yacute;ch měřičů v&nbsp;Egyptě a Řecku. N&iacute;že je velmi zestručněn&aacute; historie geod&eacute;zie.</p>
    <hr /><h2>Historie geod&eacute;zie</h2>
    <h3>Časn&eacute; civilizace</h3>
    <p>Prvn&iacute; deklarovan&eacute; zm&iacute;nky o geod&eacute;zii se vyskytuj&iacute; již před 5000 let př. n. l. Např&iacute;klad v&nbsp;Egyptě, kde se <strong>řeka Nil</strong> pod&iacute;lela na zaplavov&aacute;n&iacute; pozemků a <strong>vym&yacute;v&aacute;n&iacute; jejich hranic</strong>, bylo potřeba každ&yacute;m rok nově vyměřovat hranice pomoc&iacute; geodetick&yacute;ch měřen&iacute;. Geod&eacute;zie v t&eacute;to době sloužila předev&scaron;&iacute;m pro vytyčen&iacute; hranic pozemků a daňov&eacute; &uacute;čely, nicm&eacute;ně jej&iacute; přesnost a praktičnost byla využita i při stavěn&iacute; pyramid.</p>
    <p>Mapy a ud&aacute;losti se v&nbsp;době star&eacute;ho Egypta zaznamen&aacute;valy předev&scaron;&iacute;m na <strong>papyrus</strong>, z&nbsp;nich se v&scaron;ak př&iacute;li&scaron; nedochovalo a zn&aacute;me tak předev&scaron;&iacute;m &uacute;daje vytesan&eacute; na zdech chr&aacute;mů a hrobek. V&nbsp;Babyl&oacute;nii se pak často použ&iacute;vali hliněn&eacute; destičky. Z&nbsp;oblasti Mezopot&aacute;mie pak např&iacute;klad zn&aacute;me i tzv. <strong>kuduru</strong> &ndash; kameny, kter&eacute; dokl&aacute;daly vlastnictv&iacute; pozemku a na jejichž rytin&aacute;ch byly mj. uvedeny informace o průběhu hranice pozemku a jm&eacute;na zeměměřičů.</p>
    <p>(využit&iacute; tzv. měřičsk&eacute;ho provazce).</p>
    <h3>Star&eacute; Řecko a Ř&iacute;m</h3>
    <p>Řekov&eacute; položily geod&eacute;zii již skutečn&eacute; vědeck&eacute; z&aacute;klady, kter&eacute; byly předev&scaron;&iacute;m spojen&eacute; s určov&aacute;n&iacute;m tvaru Země. <strong>Eratosthenes</strong> kolem roku 220 př. n. l. učinil prvn&iacute; měřen&iacute; Země, pro kter&eacute; využil paprsků Slunce, vzd&aacute;lenosti mezi studnou v&nbsp;Asu&aacute;nu a Alexandri&iacute; a vzd&aacute;lenost Slunce od zenitu. D&iacute;ky tomu určil poloměr i obvod Země s&nbsp;chybou zhruba 13 %.</p>
    <p>Mezi dal&scaron;&iacute; jm&eacute;na v&iacute;ce či m&eacute;ně spojen&aacute; s&nbsp;geod&eacute;zi&iacute; v&nbsp;antick&eacute;m Řecku patř&iacute;: Thales, Pythagoras, Plat&oacute;n, Aristoteles, Archimedes, Euklidos, Heron, Appolonius.</p>
    <p>Ř&iacute;man&eacute; uplatňovali zeměměřictv&iacute; v&nbsp;praxi v mnoha pracovn&iacute;ch oborech (vyměřov&aacute;n&iacute; s&iacute;dli&scaron;ť, silnic, vojensk&yacute;ch t&aacute;borů, akvaduktů), nicm&eacute;ně roz&scaron;&iacute;řen&iacute;m vědomost&iacute; v&nbsp;samotn&eacute;m oboru geod&eacute;zie se př&iacute;li&scaron; nezab&yacute;vali. Mezi <strong>geodetick&eacute; n&aacute;stroje z&nbsp;doby</strong><strong>antick&eacute;ho Řecka a&nbsp;Ř&iacute;ma</strong> patř&iacute; např&iacute;klad:</p>
    <ul>
    <li><strong>dioptra</strong> = nivelačn&iacute; př&iacute;stroj</li>
    <li><strong>hodometr</strong> = př&iacute;stroj pro automatick&eacute; měřen&iacute; dr&aacute;hy</li>
    <li><strong>groma</strong> = př&iacute;stroj pro vytyčov&aacute;n&iacute; prav&yacute;ch &uacute;hlů</li>
    <li><strong>chorobates</strong> = nivelačn&iacute; př&iacute;stroj v&nbsp;podobě tr&aacute;mku dlouh&eacute;ho asi 6 m</li>
    <li><strong>skaf&eacute;</strong> = př&iacute;stroj pro měřen&iacute; &uacute;hlů (poloměr Země x směr na Slunce)</li>
    <li><strong>gn&oacute;m&oacute;n</strong> = př&iacute;stroj pro určen&iacute; světov&yacute;ch stran (praktick&eacute; využit&iacute; pro vytyčov&aacute;n&iacute; i mapov&aacute;n&iacute;)</li>
    </ul>
    <h3>Středověk</h3>
    <p>Ve středověku doch&aacute;z&iacute; v&nbsp;Evropě k&nbsp;&uacute;padku zeměměřičstv&iacute;. V&nbsp;arabsk&eacute; č&aacute;sti světa v&scaron;ak doch&aacute;z&iacute; k&nbsp;rozvoji, taměj&scaron;&iacute; matematici se postarali o zdokonalen&iacute; středověk&yacute;ch pomůcek. Mnoho term&iacute;nů vznikl&yacute;ch v&nbsp;t&eacute;to době se udrželo do dne&scaron;n&iacute;ch dn&iacute; (např. alhid&aacute;da, azimut, nadir).</p>
    <p>V&nbsp;t&eacute;to době doch&aacute;z&iacute; z&aacute;sluhou Arabů k&nbsp;roz&scaron;&iacute;řen&iacute; <strong>astrol&aacute;bu</strong>, z&nbsp;něhož bylo pomoc&iacute; měřen&iacute; postaven&iacute; hvězd možn&eacute; odeč&iacute;st čas a takt&eacute;ž j&iacute;m &scaron;lo měřit &uacute;hly. Z&nbsp;př&iacute;kazu Al-M&aacute;muna doch&aacute;z&iacute; k&nbsp;prvn&iacute;mu určen&iacute; velikosti Země pomoc&iacute; př&iacute;m&eacute;ho měřen&iacute; d&eacute;lky.</p>
    <p>Pro <strong>n&aacute;mořn&iacute; plavby</strong> (ale i zaměměřičstv&iacute; a astronomii) byl použ&iacute;v&aacute;n <strong>kvadrant</strong>, jenž byl nejroz&scaron;&iacute;řeněj&scaron;&iacute;m středověk&yacute;m &uacute;hloměrn&yacute;m př&iacute;strojem. Čast&eacute; bylo tak&eacute; použit&iacute; <strong>Jakubovy hole</strong>, kterou se odeč&iacute;tala nejčastěji zeměpisn&aacute; &scaron;&iacute;řka. Na palub&aacute;ch se pak použ&iacute;valy i jin&eacute; n&aacute;stroje, tyto v&scaron;ak maj&iacute; největ&scaron;&iacute; přesah do geod&eacute;zie.</p>
    <h3>Renesance a novověk</h3>
    <p>Jako v&nbsp;jin&yacute;ch věd&aacute;ch, doch&aacute;z&iacute; k&nbsp;velk&eacute;mu rozvoji geod&eacute;zie. Nach&aacute;z&iacute; nov&aacute; využit&iacute; při objevitelsk&yacute;ch plavb&aacute;ch, hornictv&iacute; a stavebnictv&iacute;. Nad&aacute;le se využ&iacute;vaj&iacute; vylep&scaron;en&eacute; formy astrol&aacute;bu, kvadrantu, kompasu, jakubovy hole. Pro měřen&iacute; vět&scaron;&iacute;ch vzd&aacute;lenost&iacute; se využ&iacute;v&aacute; ot&aacute;ček kol koč&aacute;rů.</p>
    <p>V&nbsp;tomto obdob&iacute; doch&aacute;z&iacute; k&nbsp;velk&eacute;mu rozvoji astronomie, prob&iacute;haj&iacute; velk&aacute; stupňov&aacute; měřen&iacute; určov&aacute;n&iacute; rozměrů Země aj. Kopern&iacute;k, Kepler a Galilei pokl&aacute;daj&iacute; z&aacute;klady modern&iacute; geod&eacute;zie. Jean Fernel prov&aacute;d&iacute; dosud nejpřesněj&scaron;&iacute; měřen&iacute; poloměru Země. Snellius poprv&eacute; využ&iacute;v&aacute; metodu triangulace pro měřen&iacute; d&eacute;lky.</p>
    <p>Postupně vznikaj&iacute; př&iacute;stroje podobaj&iacute;c&iacute; se dne&scaron;n&iacute;m teodolitům. V&nbsp;roce 1570 je pak prvn&iacute; použit&iacute; tohoto pojmu zaznamen&aacute;no (prozat&iacute;m jen pro č&aacute;st př&iacute;stroje) u anglick&eacute;ho vědce Leonarda Diggese.</p>
    <hr /><h2>Modern&iacute; zeměměřičstv&iacute;</h2>
    <p>Od 17.-18. stolet&iacute; doch&aacute;z&iacute; k&nbsp;podrobn&yacute;m mapov&aacute;n&iacute;m (vět&scaron;inou vojensk&aacute;) na matematick&yacute;ch z&aacute;kladech. Rovněž se zač&iacute;n&aacute; prov&aacute;dět katastr&aacute;ln&iacute; mapov&aacute;n&iacute; pro daňov&eacute; &uacute;čely. V&nbsp;souvislosti s&nbsp;rozvojem vědy a techniky vznikaj&iacute; pak geodetick&eacute; a souřadnicov&eacute; s&iacute;tě, vyrovn&aacute;vac&iacute; počet a měř&iacute;c&iacute; technika proch&aacute;z&iacute; velk&yacute;m rozvojem.</p>
    <p>Ve 20. stolet&iacute; doch&aacute;z&iacute; k&nbsp;zav&aacute;děn&iacute; automatizace, digit&aacute;ln&iacute;ch př&iacute;strojů, fotogrammetrie, metod DPZ a GPS (GNSS). Důsledkem je současn&aacute; přesnost geodetick&yacute;ch měřen&iacute; až na setiny milimetrů. Obecně se pak d&aacute; ř&iacute;ci, že největ&scaron;&iacute; rozvoj v&nbsp;současnosti zaznamen&aacute;v&aacute; tzv. <strong>družicov&aacute; geod&eacute;zie</strong>.</p>
    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Definice a dějiny geodézie</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/rozdeleni-geodezie/"><button className="inv">Rozdělení geodézie &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
